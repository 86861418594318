.m-wysiwyg {
    border:1.5px solid #e4ebf1;
    padding: 15px;
    border-radius:5px;
}

.m-wysiwyg,
.jodit_wysiwyg { 
    margin-bottom:10px;
    color: #6a6e88;
    @media (min-width: 768px) {
        padding: 20px;
    }
    p {
        margin-bottom:15px;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        overflow-wrap: break-word;
        &:last-child {
            margin-bottom:0;
        }
    }
    ul {
        margin-bottom:20px;
        &:last-child {
            margin-bottom:0;
        }
        li {
            position:relative;
            padding-left:15px;
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            overflow-wrap: break-word;
            &:after {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 8px;
                background: #306bff;
                display: block;
                position: absolute;
                top:10px;
                left:0;
            }
        }
    }
}