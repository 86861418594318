.m-menu {
    display:flex;
    align-items: center;
    background:#101634;
  
    height:100%;
    position:relative;
    top:0;
    left:0;
    width:100%;
    height:60px;
  
    justify-content: space-between;
    padding-right:16px;

    @include from(md) {
        position:fixed;
        flex-direction: column;
        width:55px;
        height:100vh;
        z-index: 1;
        flex-shrink: 0;
        padding-right:0px;
        padding-bottom:16px;
    }
    &__logo {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        background: #306bff;
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        @include from(md) {
            width: 55px;
            height: 55px;
            margin-bottom: 30px;
            margin-right:0;
        }
    }
    &__top {
        display:flex;
        @include from(md) {
            display:block;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        margin-right:15px;
        
        border-radius: 50px;
        font-size: 22px;
        color:#6a6e88;
        @include from(md) {
            margin-bottom:32px;
            margin-right:0;
        }
        &:last-child {
            margin-right:0;
            @include from(md) {
                margin-bottom:0;
                margin-right:0;
            }
        }
        &:hover {
           span {
               color:$blue;
           }
        }
     
        
    }
  
}