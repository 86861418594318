/*
Mixins

Styleguide 1.2.
*/


// @mixin respond-to
//
// Applique une media query pour le media demandé.
//
// $media   - xs, sm, md, lg
// $content - SCSS properties
//
// Styleguide 1.2.3


@mixin respond-to($media) {
    @if $media == sm {
      @media only screen and (min-width: $max-xs + 1) and (max-width: $max-sm) { @content; }
    }
    @else if $media == md {
      @media only screen and (min-width: $max-sm + 1) and (max-width: $max-md) { @content; }
    }
    @else if $media == lg {
      @media only screen and (min-width: $max-md + 1) and (max-width: $max-lg) { @content; }
    }
    @else if $media == xl {
      @media only screen and (min-width: $max-lg + 1) { @content; }
    }
  }
  
  
  // @mixin from
  //
  // Applique une media query à partir du media demandé.
  //
  // $media   - xs, sm, md, lg
  // $content - SCSS properties
  //
  // Styleguide 1.2.4
  
  @mixin from($media) {
    @if $media == sm {
      @media only screen and (min-width: $max-xs + 1) { @content; }
    }
    @else if $media == md {
      @media only screen and (min-width: $max-sm + 1) { @content; }
    }
    @else if $media == lg {
      @media only screen and (min-width: $max-md + 1) { @content; }
    }
    @else if $media == xl {
      @media only screen and (min-width: $max-lg + 1) { @content; }
    }
  }
  
  // @mixin column
  //
  // Applique un mixim determinant le nombre de colonne d'un bloc.
  //
  // $number - 1 à 12
  //
  // Styleguide 1.2.5
  
  @mixin column($number) {
    width: calc( ( 100% / 4 ) * #{$number} );
    padding:0 8px;
  
    @include respond-to(md) {
      width: calc( ( #{$max-sm} / 12 ) * #{$number} );
    }
  
    @include respond-to(lg) {
      width: calc( ( #{$max-md} / 12 ) * #{$number} );
    }
  
    @include respond-to(xl) {
      width: calc( ( #{$max-lg} / 12 ) * #{$number} ) ;

    }
  
  }
  
  
  @mixin columnOffset($number) {
    margin-left: calc((100% / 12) * #{$number} );
    
    @include respond-to(md) {
      margin-left: calc((#{$max-md} / 8) * #{$number} );
    }
    @include respond-to(lg) {
      margin-left: calc((#{$max-md} / 12) * #{$number} );
    }
    @include respond-to(xl) {
      margin-left: calc((#{$max-lg} / 12) * #{$number} );
    }
  
  }