.m-table {
    width:100%;
    border-collapse: collapse;
    display:block;
    &__head {
        display:none;
        @include from(lg) {
            display:block;
            border:1.5px solid $grey-very-light;
            border-radius:5px;
            margin-bottom:16px;
            
        }
        &__wrapper {
            display:flex;
            flex-wrap: wrap;
        }
      
        .m-table__item {
            @include from(lg) {
                padding: 10px;
                color:$grey-light;
            }
        }
    }
    &__item {
        @include from(lg) {
            padding:15px 10px;
            font-size: 14px;
            color:$grey;
            &.col-1 { width:calc((100% / 12) * 1)}
            &.col-2 { width:calc((100% / 12) * 2)}
            &.col-3 { width:calc((100% / 12) * 3)}
            &.col-4 { width:calc((100% / 12) * 4)}
            &.col-5 { width:calc((100% / 12) * 5)}
            &.col-6 { width:calc((100% / 12) * 6)}
            &.col-7 { width:calc((100% / 12) * 7)}
            &.col-8 { width:calc((100% / 12) * 8)}
            &.col-9 { width:calc((100% / 12) * 9)}
            &.col-10 { width:calc((100% / 12) * 10)}
            &.col-11 { width:calc((100% / 12) * 11)}
            &.col-12 { width:calc((100% / 12) * 12)}
            &.client {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &.buttons {
            display:flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top:8px;
            @include from(lg) {
                margin-top:0;
            }
            .icon {
                color:$blue;
                cursor: pointer;
                &:hover {
                    @include from(md) {
                        color:$blue-hover;
                    }
                }
            }
            .link {
                font-size: 20px;
                line-height: 20px;
            }
            .edit {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
    &__body {
        &__wrapper {
            margin-bottom:8px;
            border:1.5px solid $grey-very-light;
            border-radius:5px;
            padding:16px;
            @include from(lg) {
                display:flex;
                flex-wrap: wrap;
                padding:0;
               
            }
            &:last-child {
                margin-bottom:0;
            }
        }
    }
}