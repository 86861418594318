/** Colors **/



:root {
  --purple: #713e8b;
}



$purple : #713e8b;
$orange : #d4242f;
$pink : #d7576c;
$pink-hover : #ff6d92;
$main : #41bd8f;
$blue-very-light: #e0eafd;
$blue: #306bff;;
$blue-hover: #2261ff;
$grey-very-dark : #15213b;
$grey-dark : #19223b;
$grey-small-dark : #6a6e88;
$grey : #53586a;
$grey-light : #adb3bf;
$grey-very-light : #e4ebf1;
$green : #83ba5d;
$green-lignt : #c4e7ba;
$orange : #ffceab;
$orange-hover : #ff982f;
$gold : #cebe98;
$gold-light: #efe4c8;
$red: #ed5e68;
$red-hover : #da5861;

/*
Breakpoints
*/

$max-xs: 500px;
$max-sm: 767px;
$max-md: 1024px;
$max-lg: 1275px;
$max-xl : false;


$breakpoints: (
  xs: $max-xs,
  sm: $max-sm,
  md: $max-md,
  lg: $max-lg,
  xl: $max-xl
);