.l-login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background:$blue;
    &__title {
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
    }
    &__form {
        padding:40px;
        width: 100%;
        max-width:500px;
        background-color: #fff;
        border-radius: 5px;
       
        .error {
            margin-top:16px;
        }
    }
} 