.m-timer {
  width: 100%;
  @include respond-to(md) {
    padding-top: 120px;
  }
  @include from(lg) {
    padding-top: 90px;
  }
  &__header {
    z-index: 2;
    background: #fff;
    width: 100%;
    padding: 20px;
    @include from(md) {
      position: fixed;
      top: 0;
      left: 0;
      left: 245px;
      width: calc(100% - 245px);
    }
    @include from(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      padding: 20px;
    }
    &__input {
      width: 100%;
      position: relative;
      z-index: 2;
      @include from(lg) {
        width: 390px;
      }
      &__task {
        position: absolute;
        top: calc(100% + 15px);
        overflow: auto;
        left: 0;
        width: 100%;
        max-height: 300px;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #e2e2e2;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        button {
          position: relative;
          display: flex;
          align-items: center;
          padding: 8px 10px;
          border-radius: 50px;
          font-size: 14px;
          cursor: pointer;
          .taskname {
            overflow: hidden;
            max-width: 160px;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include from(lg) {
              max-width: 200px;
            }
          }
          .point {
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            margin-right: 5px;
            margin-left: 10px;
          }
          .mission {
            font-weight: 600;
            overflow: hidden;
            max-width: 160px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      @include from(lg) {
        justify-content: flex-end;
        margin-left: 20px;
        margin-top: 0;
      }
      &__project {
        margin-right: 20px;

        .icon-mission {
          display: block;
          position: relative;
          color: #306bff;

          font-size: 22px;
          line-height: 21px;
          cursor: pointer;
          &:hover {
            @include from(md) {
              color: #2261ff;
            }
          }
        }
        &__box {
          display: flex;
          align-items: center;
          width: calc(100% - 100px);
          @include from(md) {
            max-width: 500px;
            width: auto;
          }
        }

        &__mission {
          display: flex;
          align-items: center;

          padding: 10px;
          background: #f1f3f6;
          border-radius: 5px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;

          &__stop {
            margin-left: 5px;
            font-size: 25px;
            line-height: 15px;
            color: $red;
            cursor: pointer;
            &:hover {
              @include from(md) {
                color: $red-hover;
              }
            }
          }
          &__name {
            display: flex;
            align-items: center;
            color: #53586a;
            flex-shrink: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 165px;
          }
          &__slash {
            margin: 0 8px;
          }
          &__client {
            color: #6a6e88;
            overflow: hidden;
            width: 160px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  &__timer {
    display: flex;
    align-items: center;
    &__seconds {
      font-size: 18px;
      width: 80px;
      text-align: center;
      font-weight: 500;
      color: #000;
    }
    &__buttons {
      display: flex;
      align-items: center;
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #41bd8f;
        border-radius: 30px;
        cursor: pointer;
        &:hover {
          background-color: #39a77e;
        }
        color: #fff;
        &:last-child {
          margin-left: 15px;
        }
        .icon-play {
          font-size: 18px;
          margin-left: 4px;
        }
        .icon-pause {
          font-size: 16px;
        }
        .icon-stop {
          font-size: 16px;
        }
        &:disabled {
          cursor: not-allowed;
          opacity: 0.2;
        }
      }
    }
  }
}
