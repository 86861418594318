.m-form {
    &__delete {
        position:absolute;
        top:20px;
        right:20px;
    }
    &__group {
        margin-bottom:16px;
        &:last-child {
            margin-bottom:0;
        }
        &__avatar {
            margin-top:8px;
        }
        &__title {
            margin-bottom:24px;
        }
        &__wrapper {
            @include from(md) {
                display:flex;
                margin:0 -12px;
            }
            &__item {
                margin-bottom:16px;
                @include from(md) {
                    width: 100%;
                    padding:0 12px;
                    margin-bottom:0;
                } 
            }
        }
    }
    &__file {
        display:none;
    }
    &__send {
        padding-top: 16px;
        @include from(sm) {
            display:flex;
            align-items: center;
        }
        &--space {
            justify-content: space-between;
        }
        &__loading {
            margin-left:20px;
        }
        &__delete {
            margin-top:30px;
            @include from(md) {
                margin-top:0;
            }
        }
    }
}