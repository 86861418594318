.l-wrapper {
  padding-top: 30px;
  @include from(md) {
    padding-top: 0;
  }
  &__header {
    margin-bottom: 44px;
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 64px;
    }
    &--wrapper {
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__breadcrumbs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 32px;
      p {
        display: flex;
        align-items: center;
        margin-right: 16px;
        span {
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          margin-right: 8px;
        }
      }
    }

    &__left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (min-width: 1024px) {
        justify-content: flex-start;
      }
    }
    &__add {
      margin-left: 16px;
      @media (min-width: 1024px) {
        transition: all 0.2s ease-in;
        margin-left: 32px;
      }
      &__edit {
        font-size: 22px;
        cursor: pointer;
        color: #41bd8f;
        line-height: 22px;
        @media (min-width: 1024px) {
          color: #42ba86;
        }
      }
    }

    &__title {
      color: $grey-dark;
      font-family: 'Poppins', sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
      @include from(md) {
        font-size: 40px;
        line-height: 50px;
      }
    }

    &__client {
      display: inline-block;
      font-size: 15px;
      background-color: $blue-very-light;
      color: $blue;
      font-weight: 500;
      line-height: 22px;
      padding: 8px 16px;
      border-radius: 5px;
    }

    &__right {
      margin-top: 15px;
      @media (min-width: 1024px) {
        margin: 0 0 0 15px;
      }
      &__searchbar {
        position: relative;
        width: 100%;
        max-width: 350px;
        .icon-search {
          position: absolute;
          top: 16px;
          left: 16px;
          color: $grey-light;
        }
        input {
          padding-left: 45px;
        }
      }
    }
  }
  &__list {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }
}
