.m-simple-card {
    border:1.5px solid $grey-very-light;
    border-radius:5px;
    padding:16px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow:
    0 10.7px 6.6px -100px rgba(0, 0, 0, 0.006),
    0 53px 53px -100px rgba(0, 0, 0, 0.01);
  
    @include from(md) {
        padding:40px;
    }

    &__text {
        font-size: 18px;
        font-weight: 300;
        color:#53586a;
        
    }
    &__button {
        margin-top:24px;
    }
}