@font-face {
    font-family: 'icomoon';
    src:  url('../../assets/icons/icomoon.eot?fls7px');
    src:  url('../../assets/icons/icomoon.eot?fls7px#iefix') format('embedded-opentype'),
      url('../../assets/icons/icomoon.ttf?fls7px') format('truetype'),
      url('../../assets/icons/icomoon.woff?fls7px') format('woff'),
      url('../../assets/icons/icomoon.svg?fls7px#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-external-link:before {
    content: "\e91a";
  }
  .icon-invite:before {
    content: "\e919";
  }
  .icon-check:before {
    content: "\e918";
  }
  .icon-calendar:before {
    content: "\e917";
  }
  .icon-delete:before {
    content: "\e916";
  }
  .icon-link:before {
    content: "\e915";
  }
  .icon-search:before {
    content: "\e914";
  }
  .icon-remove:before {
    content: "\e913";
  }
  .icon-pause:before {
    content: "\e911";
  }
  .icon-stop:before {
    content: "\e912";
  }
  .icon-mission:before {
    content: "\e90e";
  }
  .icon-play:before {
    content: "\e90f";
  }
  .icon-timer:before {
    content: "\e910";
  }
  .icon-dots-h:before {
    content: "\e90c";
  }
  .icon-dots-v:before {
    content: "\e90d";
  }
  .icon-agenda:before {
    content: "\e90a";
  }
  .icon-agenda-plus:before {
    content: "\e90b";
  }
  .icon-left:before {
    content: "\e909";
  }
  .icon-clients:before {
    content: "\e907";
  }
  .icon-edit-2:before {
    content: "\e908";
  }
  .icon-logout:before {
    content: "\e906";
  }
  .icon-edit:before {
    content: "\e905";
  }
  .icon-download:before {
    content: "\e903";
  }
  .icon-user:before {
    content: "\e904";
  }
  .icon-add-group:before {
    content: "\e902";
  }
  .icon-menu:before {
    content: "\e900";
  }
  .icon-plus:before {
    content: "\e901";
  }
  