.m-search-mission {
    position:absolute;

    background: #e4ebf1;
    top:calc(100% + 20px);
    left:0;
    max-height: 300px;
    overflow: auto;
    width:100%;

    border-radius: 5px;
  
    @include from(md) {
        width: 330px;
        left:20px;
    }
    @include from(lg) {
        right:20px;
        left: inherit;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-track {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #3c4c87;
        outline: 1px solid slategrey;
        border-radius:30px;
    }

    &__input {
        position: sticky;
        top: 0;
        z-index: 2;
        padding: 15px;
        input {
            height: 40px;
            padding-left:45px;
           
        }
        .icon-search {
            position: absolute;
            top: 27px;
            left: 31px;
            color: #6a6e88;
            font-size: 16px;
        }
      
    }
    &__wrapper {
        padding:0 15px 0;
        margin-bottom: 20px;
    }
  
    &__label {
        display: block;
        text-transform:uppercase;
        color:$grey;
        font-size: 12px;
        font-weight: 600;
    }

    &__list {
        margin-bottom:12px;
        &__item {
            position: relative;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            border-radius: 50px;
            font-size: 15px;
            cursor: pointer;
            color: #6a6e88;
            &__point {
                display: block;
                width:8px;
                height:8px;
                border-radius: 8px;
                margin-right:10px;
                flex-shrink: 0;
            }
        }
    }
    &__empty {
        padding: 0 20px 20px;
        text-align: center;
        color: $red;
        font-size: 17px;
    }
}