%all {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
}

*,
*:after,
*:before {
    @extend %all;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
    background-color: #fff;

    font-family: 'Poppins', sans-serif;
    font-size:15px;
    color:$grey-light;
    letter-spacing: -0.2px;

    &.modal-open {
        overflow: hidden;
    }
}

.unstyled::-webkit-inner-spin-button {
    height: 100%;
}


a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

textarea,
input,
button,
select {
    -webkit-appearance: none;
    outline: none;
    background: none;
    border: none;
    max-width: 100%;
    font-family: 'Poppins', sans-serif
}

input[type=time] {
    &::-ms-clear,
    &::-webkit-clear-button {
        display: none!important;
        width: 0;
        height: 0; 
    }
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    /* Hide the cancel button */
    &::-webkit-search-cancel-button { 
        -webkit-appearance: none; 
    }

    /* Hide the magnifying glass */
    &::-webkit-search-results-button {
        -webkit-appearance: none; 
    }
}
input[type=time]::-ms-clear {
    display:none;
}



/* Remove the rounded corners */
input[type=search] { 
    -webkit-appearance: none; 
}


.container {
    width: 100%;
    padding: 0 16px;
}

.App {
    position:relative;
    @include from(md) {
        display:flex;
        
    }
}